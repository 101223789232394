import React from 'react'
import { useDispatch } from 'react-redux'

import { Capacitor } from '@capacitor/core'
import { signOut } from 'store/modules/auth/actions'

import DesktopView from './Desktop.view'
import MobileView from './Mobile.view'

function SignOut() {
  const dispatch = useDispatch()

  function handleSignOut() {
    dispatch(signOut())
    localStorage.setItem('dateConfig', null)
  }

  if (Capacitor.isNativePlatform() || window._env_?.CAPACITOR_DEBUG) {
    return <MobileView handleSignOut={handleSignOut} />
  }

  return <DesktopView handleSignOut={handleSignOut} />
}

export default SignOut
