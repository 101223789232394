import React from 'react'

import { Select } from 'antd'
import PropTypes from 'prop-types'

const DesktopView = ({ value, onChange }) => (
  <Select
    bordered={false}
    dropdownMatchSelectWidth={false}
    value={value}
    onChange={onChange}
    optionLabelProp="label"
    options={[
      { value: 'en-US', label: 'English' },
      { value: 'pt-BR', label: 'Português' },
      { value: 'es-ES', label: 'Español' },
    ]}
  />
)

DesktopView.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default DesktopView
