/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { message } from 'antd'
import { Toast } from 'antd-mobile'
import moment from 'moment'
import { checkContractSuccess } from 'store/modules/contract/actions'

import useIsMobileCapacitor from 'hooks/useIsMobileCapacitor'

import axiosClient from 'services/api'

import DesktopView from './Desktop.view'
import MobileView from './Mobile.view'

function ExpiredScreen() {
  const dispatch = useDispatch()
  const contract = useSelector(state => state.contract)
  const whiteLabel = useSelector(state => state.whiteLabel)
  const [loading, setLoading] = useState(false)
  const isMobileCapacitor = useIsMobileCapacitor()
  const intl = useIntl()

  const dayToExpire = moment(contract.expiredDate).diff(moment(), 'days')

  async function handleSubmit(values) {
    setLoading(true)

    try {
      await axiosClient
        .post('EST/queries/setNewContractDate', {
          token: values.token,
        })
        .then(resp => resp.data)
        .then(resp => {
          dispatch(checkContractSuccess(false, resp.data.data_fim_contrato))
        })
    } catch (error) {
      console.log(error)
      if (isMobileCapacitor) {
        Toast.show({
          content: intl.formatMessage({
            id: 'expired.token.invalid',
          }),
        })
      } else {
        message.error(
          intl.formatMessage({
            id: 'expired.token.invalid',
          })
        )
      }
    } finally {
      setLoading(false)
    }
  }

  if (!contract.isExpired && dayToExpire >= 30) {
    return <Redirect to="/analysis/analysis-process" />
  }

  const viewProps = {
    intl,
    contract,
    whiteLabel,
    loading,
    onSubmit: handleSubmit,
  }

  return isMobileCapacitor ? (
    <MobileView {...viewProps} />
  ) : (
    <DesktopView {...viewProps} />
  )
}

export default ExpiredScreen
