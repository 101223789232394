function isUnixTimestamp(value) {
    if (value === null || value === undefined || value === '' || typeof value === 'boolean') {
        return false;
    }

    // Remove any whitespace and check if it's different from the original
    const trimmed = String(value).trim();
    if (trimmed !== String(value)) {
        return false;
    }

    // Check if it's a valid number within safe integer range
    const num = Number(value);
    if (!Number.isInteger(num) || !Number.isSafeInteger(num)) {
        return false;
    }

    const date = new Date(num * 1000);
    return date instanceof Date && !Number.isNaN(date.getTime());
}

export default isUnixTimestamp
