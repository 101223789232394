import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import { Form } from '@ant-design/compatible'
import { LoadingOutlined } from '@ant-design/icons'
import { faDice } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Input,
  Row,
  Col,
  Radio,
  Checkbox,
  Popover,
  Divider,
  Space,
  Spin,
} from 'antd'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'

import AntModal from 'components/AntModal'

import generateRandomPassword from 'utils/generateRandomPassword'

import AvatarUpload from './AvatarUpload'
import UnitList from './UnitList'

import '@ant-design/compatible/assets/index.css'

class UserForm extends Component {
  handleSubmitEvent = e => {
    e.preventDefault()
    const { form, handleSubmit } = this.props
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        handleSubmit(values)
      }
    })
  }

  filterOption = (inputValue, option) =>
    String(option.content).includes(inputValue)

  validatePassword = (rule, value, callback) => {
    const { intl } = this.props

    if (value && value.length < 8) {
      callback(
        intl.formatMessage({
          id: 'accessControl.users.validatePassword.minimumCaracters',
        })
      )
    } else if (value && !/[A-Z]/.test(value)) {
      callback(
        intl.formatMessage({
          id: 'accessControl.users.validatePassword.upperCase',
        })
      )
    } else if (value && !/[a-z]/.test(value)) {
      callback(
        intl.formatMessage({
          id: 'accessControl.users.validatePassword.lowerCase',
        })
      )
    } else if (value && !/\d/.test(value)) {
      callback(
        intl.formatMessage({
          id: 'accessControl.users.validatePassword.number',
        })
      )
    } else if (value && !/[^a-zA-Z0-9]/.test(value)) {
      callback(
        intl.formatMessage({
          id: 'accessControl.users.validatePassword.specialCaracter',
        })
      )
    } else {
      callback()
    }
  }

  render() {
    const {
      form,
      toggleModal,
      loading,
      values,
      isProfile,
      intl,
      user: userProfile,
      units,
      usersList,
      token,
      visible,
      page = false,
    } = this.props

    const isBloked = values.usu_bloqueado === 1

    const { getFieldDecorator } = form

    const unitsToQrCode = units.map(unit => ({
      ele_descri: unit.ele_descri,
      plt_codigo: unit.plt_codigo,
      cli_codigo: unit.cli_codigo,
    }))

    const titlePage = _ => {
      if (page) {
        return intl.formatMessage({
          id: 'global.labels.profile',
        })
      }

      if (values.id) {
        return intl.formatMessage({ id: 'global.button.update' })
      }

      return intl.formatMessage({ id: 'global.button.insert' })
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} />
    return (
      <AntModal
        width={700}
        title={titlePage()}
        visible={visible}
        onOk={toggleModal}
        onCancel={() => toggleModal(false)}
        footer={null}
      >
        {!isBloked ? (
          <Form layout="vertical" onSubmit={this.handleSubmitEvent}>
            <Spin spinning={loading} size="middle" indicator={antIcon}>
              <Row gutter={[16, 16]}>
                <Col sm={6} xs={24}>
                  <Form.Item>
                    {getFieldDecorator('usu_foto')(<AvatarUpload />)}
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label={intl.formatMessage({
                          id: 'accessControl.users.name',
                        })}
                      >
                        {getFieldDecorator('usu_nome', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'global.labels.requiredField',
                              }),
                            },
                            {
                              max: 50,
                              message: intl.formatMessage({
                                id: 'accessControl.users.maxLengthExceeded',
                              }),
                            },
                          ],
                        })(<Input type="text" />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <Form.Item
                        style={{ marginRight: '10px' }}
                        label={intl.formatMessage({
                          id: 'accessControl.users.login',
                        })}
                      >
                        {getFieldDecorator('usu_login', {
                          rules: [
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'global.labels.requiredField',
                              }),
                            },
                            {
                              max: 50,
                              message: intl.formatMessage({
                                id: 'accessControl.users.maxLengthExceeded',
                              }),
                            },
                            {
                              validator: async (rule, value) => {
                                const usersFiltered = usersList.filter(
                                  t => t.id_usuario !== values?.id_usuario
                                )

                                if (
                                  usersFiltered.find(t => t.usu_login === value)
                                ) {
                                  throw new Error(
                                    intl.formatMessage({
                                      id: 'global.labels.userExists',
                                    })
                                  )
                                }
                              },
                            },
                          ],
                        })(<Input type="text" />)}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={intl.formatMessage({
                          id: 'global.labels.password',
                        })}
                        extra={intl.formatMessage({
                          id: 'accessControl.users.passwordExtra',
                        })}
                      >
                        {getFieldDecorator('usu_senha', {
                          rules: [
                            {
                              required: !values.id,
                              message: intl.formatMessage({
                                id: 'global.labels.requiredField',
                              }),
                            },
                            {
                              validator: this.validatePassword,
                            },
                          ],
                        })(
                          <Input.Password type="text" placeholder="*********" />
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <Form.Item>
                        <Button
                          style={{ marginTop: '30px' }}
                          onClick={() =>
                            form.setFieldsValue({
                              usu_senha: generateRandomPassword(),
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faDice} />
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={intl.formatMessage({
                      id: 'accessControl.users.email',
                    })}
                  >
                    {getFieldDecorator('usu_email', {
                      rules: [
                        {
                          type: 'email',
                          message: intl.formatMessage({
                            id: 'accessControl.users.insertValidEmail',
                          }),
                        },
                        {
                          max: 400,
                          message: intl.formatMessage({
                            id: 'accessControl.users.maxLengthExceeded',
                          }),
                        },
                      ],
                    })(<Input type="text" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={intl.formatMessage({
                      id: 'accessControl.users.accountType',
                    })}
                  >
                    {getFieldDecorator('usu_tipo', {
                      initialValue: '1',
                      rules: [
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'global.labels.requiredField',
                          }),
                        },
                      ],
                    })(
                      <Radio.Group disabled={isProfile} buttonStyle="solid">
                        <Radio.Button value="1">
                          {intl.formatMessage({
                            id: 'accessControl.users.adm',
                          })}
                        </Radio.Button>
                        <Radio.Button value="2">
                          {intl.formatMessage({ id: 'global.labels.user' })}
                        </Radio.Button>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item>
                    {getFieldDecorator('usu_ativo', {
                      valuePropName: 'checked',
                    })(
                      <Checkbox disabled={isProfile}>
                        {intl.formatMessage({
                          id: 'accessControl.users.activeUser',
                        })}
                      </Checkbox>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              {values.id && !isProfile && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <UnitList
                      units={values.units}
                      user={values}
                      loadingTable={loading}
                    />
                  </Col>
                </Row>
              )}
            </Spin>
            <Row gutter={16}>
              <Divider />
              <Col span={12}>
                <div style={{ float: 'left' }}>
                  <Space>
                    <Popover
                      content={
                        <QRCode
                          size={384}
                          value={JSON.stringify({
                            token,
                            user: {
                              cli_codigo: userProfile.cli_codigo,
                              plt_codigo: userProfile.plt_codigo,
                              id_usuario: userProfile.id_usuario,
                              usu_nome: userProfile.usu_nome,
                              usu_foto: userProfile.usu_foto,
                            },
                            url: window._env_?.ENV_API_URL,
                            units: unitsToQrCode,
                          })}
                        />
                      }
                    >
                      <Button>
                        {intl.formatMessage({ id: 'global.button.qrCode' })}
                      </Button>
                    </Popover>

                    <Popover
                      content={
                        <QRCode
                          size={384}
                          value={JSON.stringify({
                            apiUrl: window._env_?.ENV_API_URL,
                            username: userProfile.usu_login,
                          })}
                        />
                      }
                    >
                      <Button>
                        {intl.formatMessage({ id: 'global.button.qrCode' })} App
                      </Button>
                    </Popover>
                  </Space>
                </div>
              </Col>

              <Col span={12}>
                <div style={{ float: 'right' }}>
                  <Form.Item style={{ margin: '0px' }}>
                    <Space>
                      <Button
                        type="default"
                        htmlType="button"
                        onClick={() => toggleModal()}
                      >
                        {intl.formatMessage({ id: 'global.button.cancel' })}
                      </Button>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        // onClick={() => form.submit()}
                      >
                        {intl.formatMessage({ id: 'global.button.save' })}
                      </Button>
                    </Space>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        ) : (
          <Form layout="vertical" onSubmit={this.handleSubmitEvent}>
            <Row gutter={[16, 16]}>
              <Col sm={6} xs={24}>
                <Form.Item>
                  {getFieldDecorator('usu_foto')(<AvatarUpload />)}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('usu_ativo', {
                    valuePropName: 'checked',
                  })(
                    <Checkbox disabled={isProfile}>
                      {intl.formatMessage({
                        id: 'accessControl.users.activeUser',
                      })}
                    </Checkbox>
                  )}
                </Form.Item>
              </Col>
              <Col span={18}>
                <Row>
                  <Col span={20}>
                    <Form.Item
                      label={intl.formatMessage({
                        id: 'accessControl.users.name',
                      })}
                    >
                      {getFieldDecorator('usu_nome')(
                        <Input disabled type="text" />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={20}>
                    <Form.Item
                      label={intl.formatMessage({
                        id: 'accessControl.users.login',
                      })}
                    >
                      {getFieldDecorator('usu_login')(
                        <Input disabled type="text" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={20}>
                    <Form.Item
                      label={intl.formatMessage({
                        id: 'accessControl.users.email',
                      })}
                    >
                      {getFieldDecorator('usu_email')(
                        <Input disabled type="text" />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={20}>
                    <Form.Item
                      label={intl.formatMessage({
                        id: 'global.labels.password',
                      })}
                      extra={intl.formatMessage({
                        id: 'accessControl.users.passwordCorrection',
                      })}
                    >
                      {getFieldDecorator('usu_senha', {
                        rules: [
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'global.labels.requiredField',
                            }),
                          },
                          {
                            validator: this.validatePassword,
                          },
                        ],
                      })(
                        <Input.Password type="text" placeholder="*********" />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <Row>
              <Col span={24}>
                <div style={{ float: 'right' }}>
                  <Form.Item style={{ margin: '0px' }}>
                    <Space>
                      <Button
                        type="default"
                        htmlType="button"
                        onClick={() => toggleModal()}
                      >
                        {intl.formatMessage({ id: 'global.button.cancel' })}
                      </Button>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                      >
                        {intl.formatMessage({
                          id: 'accessControl.users.unblockUser',
                        })}
                      </Button>
                    </Space>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </AntModal>
    )
  }
}

UserForm.defaultProps = {
  loading: false,
}

UserForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    setFieldsValue: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
  }).isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  isProfile: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  token: PropTypes.string.isRequired,
  units: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  usersList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({
    cli_codigo: PropTypes.string,
    id_usuario: PropTypes.number,
    plt_codigo: PropTypes.string,
    usu_foto: PropTypes.string,
    usu_nome: PropTypes.string,
  }).isRequired,
  values: PropTypes.shape({
    id: PropTypes.number.isRequired,
    id_usuario: PropTypes.number.isRequired,
    units: PropTypes.arrayOf(PropTypes.shape({})),
    usu_bloqueado: PropTypes.number,
  }).isRequired,
  visible: PropTypes.bool.isRequired,
  page: PropTypes.bool.isRequired,
}

const WrappedUserForm = Form.create({
  name: 'UserForm',
  mapPropsToFields(props) {
    return {
      usu_nome: Form.createFormField({
        value: props.values.usu_nome,
      }),
      usu_login: Form.createFormField({
        value: props.values.usu_login,
      }),
      usu_email: Form.createFormField({
        value: props.values.usu_email,
      }),
      usu_tipo: Form.createFormField({
        value: props.values.usu_tipo,
      }),
      usu_foto: Form.createFormField({
        value: props.values.usu_foto,
      }),
      usu_ativo: Form.createFormField({
        value: props.values.usu_ativo === 'S',
      }),
      usu_senha: Form.createFormField({
        value: '',
      }),
    }
  },
})(UserForm)

const mapStateToProps = state => ({
  user: state.user.profile,
  units: state.units.data,
  token: state.auth.token,
})

export default connect(mapStateToProps)(injectIntl(WrappedUserForm))
