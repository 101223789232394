import React from 'react'

import { faMoon, faSun } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const DesktopView = ({ toggleTheme, theme }) => (
  <FontAwesomeIcon
    onClick={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')}
    style={{
      fontSize: '18px',
      alignSelf: 'center',
      margin: '0 15px',
      cursor: 'pointer',
    }}
    icon={theme === 'dark' ? faSun : faMoon}
    data-testid="themeswitchericon"
  />
)

DesktopView.propTypes = {
  theme: PropTypes.string.isRequired,
  toggleTheme: PropTypes.func.isRequired,
}

export default DesktopView
