import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FixedSizeList as VirtualizedList } from 'react-window'

import Icon from '@ant-design/icons'
import { faBuilding } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popup, List, AutoCenter, Button } from 'antd-mobile'
import { CheckOutline, DownOutline } from 'antd-mobile-icons'
import PropTypes from 'prop-types'
import { checkContractRequest } from 'store/modules/contract/actions'
import { setUnitSelected } from 'store/modules/unitSelected/actions'

function DropdownMobile({ showButton }) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const unitSelected = useSelector(state => state.unitSelected.data)
  const allUnits = useSelector(state => state.units.data)

  function formatUnitLabel(unit) {
    if (unit) {
      return `${unit.plt_codigo} - ${unit.ele_descri}`
    }

    return null
  }

  function getUnit(pltCode) {
    return allUnits.find(u => u.plt_codigo === pltCode)
  }

  async function handleChangeUnit(option) {
    dispatch(setUnitSelected(getUnit(option)))
    dispatch(checkContractRequest())

    history.push('/')
  }

  return (
    <>
      {showButton ? (
        <Button
          onClick={() => setDropdownOpen(true)}
          fill="none"
          size="small"
          style={{ color: '#f5f5f5' }}
        >
          <span>{formatUnitLabel(getUnit(unitSelected))}</span>
          <DownOutline style={{ marginLeft: 10 }} />
        </Button>
      ) : (
        <Icon
          data-testid="dropdown-units"
          onClick={() => setDropdownOpen(true)}
          style={{
            fontSize: 18,
            lineHeight: '64px',
            alignSelf: 'center',
            cursor: 'pointer',
            padding: '0 15px',
          }}
          component={() => <FontAwesomeIcon icon={faBuilding} />}
        />
      )}

      <Popup
        visible={dropdownOpen}
        onMaskClick={() => setDropdownOpen(false)}
        bodyStyle={{ height: '60vh' }}
      >
        <VirtualizedList
          height={60 * (window.innerHeight / 100)}
          itemSize={50}
          itemCount={allUnits.length}
          width="100%"
        >
          {({ index, style }) => {
            const item = allUnits[index]
            return (
              <div style={{ ...style, padding: '10px' }} key={item.plt_codigo}>
                <List.Item
                  key={item.plt_codigo}
                  onClick={() => {
                    handleChangeUnit(item.plt_codigo)
                    setDropdownOpen(false)
                  }}
                  arrowIcon={false}
                >
                  <div
                    style={{
                      display: 'ruby-text',
                    }}
                  >
                    <AutoCenter>{formatUnitLabel(item)}</AutoCenter>
                    {unitSelected === item.plt_codigo && (
                      <CheckOutline
                        style={{
                          float: 'right',
                          color: '#faad14',
                          cursor: 'pointer',
                        }}
                      />
                    )}
                  </div>
                </List.Item>
              </div>
            )
          }}
        </VirtualizedList>
      </Popup>
    </>
  )
}

DropdownMobile.defaultProps = {
  showButton: false,
}

DropdownMobile.propTypes = {
  showButton: PropTypes.bool,
}

export default DropdownMobile
