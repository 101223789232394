import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Redirect, useLocation } from 'react-router-dom'

import { Button, message, Space, Popconfirm } from 'antd'
import getValidRoute from 'helpers/getValidRoute'
import PropTypes from 'prop-types'
import { signOut } from 'store/modules/auth/actions'

import AntModal from 'components/AntModal'
import {
  links,
  configLinks,
} from 'components/Layout/Sidebar/Components/Menu/links'

import useIsMobileCapacitor from 'hooks/useIsMobileCapacitor'

import axiosClient from 'services/api'

import ChangePassword from '../pages/auth/ChangePassword/ChangePasswordOnSignIn'
import { signInSuccess } from '../store/modules/auth/actions'

const RouteWrapper = ({ component: Component, isPrivate, ...rest }) => {
  const { token, signed, domain, userDataPermission, forcePasswordChange } =
    useSelector(state => state.auth)
  const isExpired = useSelector(state => state.contract.isExpired)
  const unitSelected = useSelector(state => state.unitSelected?.data)
  const units = useSelector(state => state.units)
  const accessControl = useSelector(state => state.accessControl.data)
  const user = useSelector(state => state.user?.profile)
  const { pathname, search } = useLocation()
  const [loading, setLoading] = useState(false)
  const [visibleModalPermission, setVisibleModalPermission] = useState(false)
  const [visibleModalChangePassword, setVisibleModalChangePassword] =
    useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()
  const isMobileCapacitor = useIsMobileCapacitor()

  useEffect(() => {
    if (signed && userDataPermission === 0) {
      setVisibleModalPermission(true)
    } else if (signed && forcePasswordChange === 1) {
      setVisibleModalChangePassword(true)
    }
  }, [forcePasswordChange, userDataPermission, signed])

  const mainRoutes = user?.hasAccess
    ? links
    : links
        .filter(
          mRoute =>
            accessControl?.[mRoute.id]?.read ||
            (Array.isArray(mRoute.children) &&
              mRoute.children.filter(
                subMenu =>
                  accessControl?.[subMenu.id]?.read ||
                  String(subMenu.to.pathname).includes('dashboard')
              ).length > 0)
        )
        // Filter submenus
        .map(mRoute => ({
          ...mRoute,
          children:
            Array.isArray(mRoute.children) &&
            mRoute.children.filter(
              subMenu =>
                accessControl?.[subMenu.id]?.read ||
                String(subMenu.to.pathname).includes('dashboard')
            ),
        }))

  const configRoutes = user?.hasAccess
    ? configLinks
    : configLinks
        .filter(
          mRoute =>
            accessControl?.[mRoute.id]?.read ||
            (Array.isArray(mRoute.children) &&
              mRoute.children.filter(
                subMenu => accessControl?.[subMenu.id]?.read
              ).length > 0)
        )
        // Filter submenus
        .map(mRoute => ({
          ...mRoute,
          children:
            Array.isArray(mRoute.children) &&
            mRoute.children.filter(
              subMenu => accessControl?.[subMenu.id]?.read
            ),
        }))

  const currentUnit = units.data.find(e => e.plt_codigo === unitSelected)

  const ContainsAny = (str, items) =>
    !!items.find(item => str.indexOf(item) > -1)

  if (!signed && isPrivate) {
    if (domain) {
      return (
        <Redirect
          to={{
            pathname: '/',
            search: `?domain=${domain}`,
            state: { from: `${pathname}${search}` },
          }}
        />
      )
    }
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: `${pathname}${search}` },
        }}
      />
    )
  }

  if (signed && isExpired && pathname !== '/expired') {
    return <Redirect to="/expired" />
  }

  // Save last page
  // if (signed && redirectTo) {
  //   return <Redirect to={redirectTo} />
  // }

  if (signed && !isPrivate) {
    if (isMobileCapacitor) {
      return <Redirect to="/home" />
    }

    if (currentUnit?.initial_page) {
      return <Redirect to={currentUnit.initial_page} />
    }

    if (getValidRoute([...mainRoutes, ...configRoutes])) {
      return <Redirect to={getValidRoute([...mainRoutes, ...configRoutes])} />
    }
  }

  const handleSubmitPermission = async (permissionAnswer = null) => {
    setLoading(true)
    try {
      await axiosClient.put(`CAC/entities/USUARIO/${user.id}`, {
        usu_permissao_dados: permissionAnswer ? 0 : 1,
        usu_ativo: permissionAnswer || 'S',
      })

      const userSituation = await axiosClient
        .get(`CAC/entities/USUARIO/${user.id}`)
        .then(resp => resp.data)
        .then(resp => resp.data)

      const userToDispatch = {
        ...userSituation,
        hasAccess: userSituation.usu_tipo === '1',
        pltCode: userSituation.plt_codigo,
        cliCode: userSituation.cli_codigo,
      }

      dispatch(
        permissionAnswer
          ? dispatch(signOut())
          : dispatch(
              signInSuccess(
                token,
                userToDispatch,
                userSituation.usu_permissao_dados,
                userSituation.usu_forcar_nova_senha
              )
            )
      )
    } catch (error) {
      console.log(error)
      message.error(
        intl.formatMessage({ id: 'global.error.messages.defaultError' })
      )
    } finally {
      setVisibleModalPermission(false)
      setLoading(false)
    }
  }

  const permission = (
    <AntModal
      visible={visibleModalPermission}
      width="40%"
      title={intl.formatMessage({ id: 'auth.signin.welcomeToIntelup' })}
      onOk={() => setVisibleModalPermission(false)}
      onCancel={() => setVisibleModalPermission(false)}
      closable={false}
      maskClosable={false}
      keyboard={false}
      footer={
        <Space>
          <Popconfirm
            placement="topRight"
            title={intl.formatMessage({
              id: 'global.labels.notAllowConfirmation',
            })}
            onConfirm={() => {
              handleSubmitPermission('N')
            }}
            okText={intl.formatMessage({
              id: 'global.labels.yes',
            })}
            cancelText={intl.formatMessage({
              id: 'global.labels.no',
            })}
          >
            <Button type="default" htmlType="button">
              {intl.formatMessage({ id: 'global.button.notAllow' })}
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={() => {
              handleSubmitPermission()
            }}
          >
            {intl.formatMessage({ id: 'global.button.allow' })}
          </Button>
        </Space>
      }
    >
      <Space direction="vertical" size="middle">
        {intl.formatMessage({ id: 'auth.signin.permissionNotification' })}
        {intl.formatMessage({
          id: 'auth.signin.permissionNotificationInformations',
        })}
        {intl.formatMessage({
          id: 'auth.signin.permissionNotificationQuestion',
        })}
      </Space>
    </AntModal>
  )

  if (user?.hasAccess) {
    return (
      <>
        <Route {...rest} component={Component} />
        {visibleModalPermission ? permission : null}
      </>
    )
  }

  function verifyIsSettings(match, location) {
    return match.path.indexOf('/settings') > -1
      ? location.pathname
      : match.path.replace('/:id', '')
  }

  return (
    <>
      <Route
        {...rest}
        render={routeProps => {
          const { match, location } = routeProps

          const path = ContainsAny(
            location.pathname,
            Object.values(match.params)
          )
            ? location.pathname.substring(
                0,
                location.pathname.indexOf(Object.values(match.params)) - 1
              )
            : verifyIsSettings(match, location)

          if (path === '/') {
            return <Component {...routeProps} />
          }

          // melhorar esse código, refatorar ele recursivamente
          const routes = [...mainRoutes, ...configRoutes]
            .map(r => (r.children ? r.children : r))
            .flat()
            .map(r => (r.children ? r.children : r))
            .flat()
            .map(r => r.to.pathname)

          if (routes.length || path === '/login' || path === '/expired') {
            if (
              [
                ...routes,
                '/login',
                '/expired',
                '/root-cause',
                '/settings/integration/api',
              ].indexOf(path) > -1
            ) {
              return <Component {...routeProps} />
            }

            return (
              <Redirect
                to={{
                  pathname: getValidRoute([...mainRoutes, ...configRoutes]),
                  state: { from: path },
                }}
              />
            )
          }

          return <div />
        }}
      />
      {permission}
      <ChangePassword
        visibleFormModal={visibleModalChangePassword}
        setVisibleFormModal={setVisibleModalChangePassword}
      />
    </>
  )
}

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.shape({}),
  ]).isRequired,
  isPrivate: PropTypes.bool,
}

RouteWrapper.defaultProps = {
  isPrivate: false,
}

export default RouteWrapper
