import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Capacitor } from '@capacitor/core'
import { message } from 'antd'
import PropTypes from 'prop-types'
import { updateProfile } from 'store/modules/user/actions'

import axiosClient from 'services/api'

import DesktopView from './Desktop.view'
import MobileView from './Mobile.view'

const UserProfileMenu = ({ size }) => {
  const [visible, setVisible] = useState(false)
  const [items, setItems] = useState([])

  const userProfile = useSelector(state => state.user.profile)

  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    axiosClient
      .get('CAC/entities/USUARIO')
      .then(response => response.data)
      .then(response => setItems(response.data))
  }, [])

  const handleSubmit = async data => {
    const values = {
      ...data,
      usu_ativo: 'S',
    }

    if (data.usu_senha.length === 0) {
      delete values.usu_senha
    }

    await axiosClient.postOrPut(
      'CAC/entities/USUARIO',
      userProfile.id_usuario,
      values
    )

    dispatch(updateProfile(values.usu_foto, values.usu_nome, values.usu_email))

    setVisible(false)

    message.success(
      intl.formatMessage({
        id: 'global.success.messages.update',
      })
    )
  }

  return Capacitor.isNativePlatform() || window._env_?.CAPACITOR_DEBUG ? (
    <MobileView
      visible={visible}
      setVisible={setVisible}
      userProfile={userProfile}
      handleSubmit={handleSubmit}
      items={items}
      size={size}
    />
  ) : (
    <DesktopView
      visible={visible}
      setVisible={setVisible}
      userProfile={userProfile}
      handleSubmit={handleSubmit}
      items={items}
    />
  )
}

UserProfileMenu.defaultProps = {
  size: 'default',
}

UserProfileMenu.propTypes = {
  size: PropTypes.oneOf(PropTypes.number, PropTypes.string),
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    photo: PropTypes.string,
  }).isRequired,
}

export default UserProfileMenu
