import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { Divider, Form, Layout, Input, Card, Typography, Button } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import BaseHeader from 'components/Layout/Header'

const { TextArea } = Input
const { Paragraph, Title } = Typography
const { Footer, Content } = Layout

const DesktopView = ({ intl, contract, whiteLabel, loading, onSubmit }) => {
  const history = useHistory()

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <BaseHeader
        showCollapse={false}
        showLogbook={false}
        showAlert={false}
        showUserProfile={false}
      />
      <Content style={{ minHeight: 'auto', padding: '50px' }}>
        <Card bodyStyle={{ minHeight: 280 }} bordered={false}>
          <Typography>
            <Title level={3}>
              <FormattedMessage id="breadcrumbs.expired.title" />
            </Title>
            <Paragraph>
              <FormattedMessage id="expired.paragragh" />
            </Paragraph>
          </Typography>
          <Divider />
          <Paragraph>
            <FormattedMessage id="expired.key" />
          </Paragraph>
          <Form onFinish={onSubmit}>
            <Form.Item
              name="token"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'global.labels.requiredField',
                  }),
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} htmlType="submit" type="primary">
                {intl.formatMessage({ id: 'global.button.validateKey' })}
              </Button>
            </Form.Item>
          </Form>
          {!contract.isExpired && (
            <Button onClick={() => history.goBack()}>
              {intl.formatMessage({ id: 'global.button.back' })}
            </Button>
          )}
        </Card>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        {whiteLabel.title} ©{moment().format('YYYY')}
      </Footer>
    </Layout>
  )
}

DesktopView.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  contract: PropTypes.shape({
    expiredDate: PropTypes.string,
    isExpired: PropTypes.bool,
  }),
  whiteLabel: PropTypes.shape({
    title: PropTypes.string,
    favicon: PropTypes.string,
    logo: PropTypes.string,
    logoWhite: PropTypes.string,
    color: PropTypes.string,
    loading: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

DesktopView.defaultProps = {
  contract: {
    expiredDate: null,
  },
}

export default DesktopView
