import React from 'react'

import { faMoon, faSun } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import PropTypes from 'prop-types'

const MobileView = ({ toggleTheme, theme }) => (
  <Button
    onClick={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')}
    icon={
      <FontAwesomeIcon
        style={{
          fontSize: '18px',
          alignSelf: 'center',
          margin: '0 5px',
          cursor: 'pointer',
        }}
        icon={theme === 'dark' ? faMoon : faSun}
        data-testid="themeswitchericon"
      />
    }
  />
)

MobileView.propTypes = {
  theme: PropTypes.string.isRequired,
  toggleTheme: PropTypes.func.isRequired,
}

export default MobileView
