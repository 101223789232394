import React from 'react'
import { useSelector } from 'react-redux'

import { Avatar, Row, Col, Typography, Divider, Space, Layout } from 'antd'

import LanguageSelector from 'components/Layout/Header/Components/LanguageSelector'
import SignOut from 'components/Layout/Header/Components/SignOut'
import ThemeSwitcher from 'components/Layout/Header/Components/ThemeSwitcher'

const { Content } = Layout

const { Title } = Typography

const UserProfile = () => {
  const userProfile = useSelector(state => state.user.profile)

  return (
    <Content style={{ padding: '16px' }}>
      <Row align="middle" justify="center" gutter={[16, 16]}>
        <Col>
          <Avatar
            size={96}
            src={userProfile?.usu_foto || '/styles/img/avatars/avatar.png'}
            alt="avatar"
          />
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col>
          <Space direction="vertical">
            <Title level={5}>{userProfile.usu_nome}</Title>
            {userProfile.usu_email}
          </Space>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col>
          <ThemeSwitcher />
        </Col>
      </Row>
      <Divider />
      <Row align="middle" justify="center">
        <Col>
          <LanguageSelector />
        </Col>
      </Row>
      <Divider />
      <Row align="middle" justify="end">
        <SignOut />
      </Row>
    </Content>
  )
}

export default UserProfile
