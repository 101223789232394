import React from 'react'
import { useIntl } from 'react-intl'

import { Avatar, Tooltip } from 'antd'
import Form from 'pages/configuration/user/Components/Form'
import PropTypes from 'prop-types'

import { Container } from './styles'

function DesktopView({
  setVisible,
  visible,
  userProfile,
  handleSubmit,
  items,
}) {
  const intl = useIntl()

  function profileLabel() {
    return intl.formatMessage({
      id: 'global.labels.profile',
    })
  }

  return (
    <Container>
      <Tooltip
        title={`${profileLabel()}: ${userProfile?.usu_nome} -  ${
          userProfile?.usu_login
        }`}
      >
        <Avatar
          size="default"
          src={userProfile?.usu_foto || '/styles/img/avatars/avatar.png'}
          onClick={() => setVisible(true)}
          style={{
            cursor: 'pointer',
            marginLeft: 16,
            marginRight: 16,
          }}
        />
      </Tooltip>

      <Form
        isProfile
        toggleModal={setVisible}
        handleSubmit={handleSubmit}
        values={userProfile}
        usersList={items}
        visible={visible}
        page
      />
    </Container>
  )
}

DesktopView.propTypes = {
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  userProfile: PropTypes.shape({
    usu_login: PropTypes.string,
    usu_foto: PropTypes.string,
    id_usuario: PropTypes.number,
    usu_nome: PropTypes.string,
    usu_email: PropTypes.string,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id_usuario: PropTypes.number,
      usu_nome: PropTypes.string,
    })
  ).isRequired,
}

export default DesktopView
