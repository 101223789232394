import produce from 'immer'

const supportedLocales = ['en-US', 'pt-BR', 'es-ES']

const navigatorLocale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en-US'

const isLocaleSupported = supportedLocales.includes(navigatorLocale)

const INITIAL_STATE = {
  key: isLocaleSupported ? navigatorLocale : 'en-US',
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const newDraft = draft

    switch (action.type) {
      case '@locale/SET_KEY': {
        newDraft.key = action.payload.key
        break
      }

      default:
    }
  })
