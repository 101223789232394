import React from 'react'

import Icon from '@ant-design/icons'
import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

function DesktopView({ handleSignOut }) {
  return (
    <Icon
      onClick={handleSignOut}
      style={{
        fontSize: 18,
        alignSelf: 'center',
        cursor: 'pointer',
        margin: '0 15px',
      }}
      component={() => <FontAwesomeIcon icon={faSignOutAlt} />}
      data-testid="signoutid"
    />
  )
}

DesktopView.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
}

export default DesktopView
