import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Capacitor } from '@capacitor/core'
import { changeCurrentTheme } from 'store/modules/theme/actions'

import DesktopView from './Desktop.view'
import MobileView from './Mobile.view'

function ThemeSwitcher() {
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme.currentTheme)

  const toggleTheme = value => {
    dispatch(changeCurrentTheme(value))
  }

  if (Capacitor.isNativePlatform() || window._env_?.CAPACITOR_DEBUG) {
    return <MobileView theme={theme} toggleTheme={toggleTheme} />
  }

  return <DesktopView theme={theme} toggleTheme={toggleTheme} />
}

export default ThemeSwitcher
