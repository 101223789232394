import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Capacitor } from '@capacitor/core'
import { setLocale } from 'store/modules/locale/actions'

import DesktopView from './Desktop.view'
import MobileView from './Mobile.view'

const LanguageSelector = () => {
  const localeKey = useSelector(state => state.locale.key)
  const dispatch = useDispatch()

  const handleChange = key => dispatch(setLocale(key))

  if (Capacitor.isNativePlatform() || window._env_?.CAPACITOR_DEBUG) {
    return <MobileView value={localeKey} onChange={handleChange} />
  }

  return <DesktopView value={localeKey} onChange={handleChange} />
}

export default LanguageSelector
