/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import {
  EyeInvisibleOutlined,
  EyeOutlined,
  QrcodeOutlined,
} from '@ant-design/icons'
import { CapacitorBarcodeScanner } from '@capacitor/barcode-scanner'
import { Preferences } from '@capacitor/preferences'
import {
  SafeArea,
  Button,
  Form,
  Input,
  Card,
  Image,
  Space,
  Result,
} from 'antd-mobile'

import axiosClient from 'services/api'

const MobileView = ({ intl, errorMessage, loading, handleSubmit, attempt }) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [form] = Form.useForm()

  const startScan = async () => {
    try {
      const result = await CapacitorBarcodeScanner.scanBarcode({
        hint: 'ALL',
        scanInstructions: 'Point the camera at a QR code',
        scanButton: true,
        scanText: 'Tap to scan',
        cameraDirection: 1, // BACK camera
        scanOrientation: 1, // PORTRAIT
      })

      if (result.ScanResult) {
        try {
          // Expecting QR content in format: {"apiUrl": "url", "username": "user"}
          const qrData = JSON.parse(result.ScanResult)

          if (qrData.apiUrl || qrData.username) {
            form.setFieldsValue({
              apiUrl: qrData.apiUrl || '',
              username: qrData.username || '',
            })
          }
        } catch (e) {
          console.error('Invalid QR code format:', e)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onFinish = async values => {
    window._env_.ENV_API_URL = values.apiUrl
    axiosClient.defaults.baseURL = `${values.apiUrl}/intelup/`

    localStorage.setItem('CapacitorStorage.apiUrl', values.apiUrl)

    await Preferences.set({
      key: 'apiUrl',
      value: values.apiUrl,
    })

    handleSubmit(values)
  }

  return (
    <div>
      <div>
        <SafeArea position="top" />
      </div>
      <Space justify="center" block>
        <Image
          src={`${process.env.PUBLIC_URL}img/logo.png`}
          alt="Logo"
          width={250}
          height={100}
          fit="contain"
        />
      </Space>
      <Card>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          mode="card"
          footer={
            <>
              <Button
                block
                type="submit"
                htmlType="submit"
                size="large"
                color="primary"
                className="login-form-button"
                loading={loading}
              >
                {intl.formatMessage({ id: 'global.button.enter' })}
              </Button>
            </>
          }
        >
          <Form.Header>
            <Space justify="between" block>
              Login
              <Button size="small" onClick={startScan}>
                <QrcodeOutlined /> QR Code
              </Button>
            </Space>
          </Form.Header>
          <Form.Item
            name="apiUrl"
            label="URL API"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'auth.signin.apiUrl',
                }),
              },
            ]}
          >
            <Input
              size="large"
              disabled={loading}
              autocorrect="off"
              autocapitalize="off"
              placeholder="https://api.intelup.com.br"
            />
          </Form.Item>
          <Form.Item
            name="username"
            label={intl.formatMessage({
              id: 'global.labels.user',
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'auth.signin.typeUser',
                }),
              },
            ]}
          >
            <Input
              size="large"
              disabled={loading}
              autocorrect="off"
              autocapitalize="off"
              placeholder="John Doe"
            />
          </Form.Item>
          <Form.Item
            name="password"
            label={intl.formatMessage({
              id: 'global.labels.password',
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'auth.signin.typePassword',
                }),
              },
            ]}
            extra={
              <div
                onClick={() => setPasswordVisible(!passwordVisible)}
                onKeyDown={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault()
                    setPasswordVisible(!passwordVisible)
                  }
                }}
                role="button"
                tabIndex={0}
                style={{ cursor: 'pointer' }}
              >
                {passwordVisible ? (
                  <EyeOutlined style={{ fontSize: 20 }} />
                ) : (
                  <EyeInvisibleOutlined style={{ fontSize: 20 }} />
                )}
              </div>
            }
          >
            <Input
              size="large"
              disabled={loading}
              autocorrect="off"
              type={passwordVisible ? 'text' : 'password'}
              autocapitalize="off"
              placeholder="********"
            />
          </Form.Item>
        </Form>
        {errorMessage && (
          <Result
            status="error"
            title={
              attempt
                ? `${attempt} ${intl.formatMessage({
                    id: errorMessage,
                  })}`
                : intl.formatMessage({ id: errorMessage })
            }
            description={
              errorMessage === 'global.error.messages.serverError'
                ? window._env_.ENV_API_URL
                : null
            }
          />
        )}
      </Card>
      <div>
        <SafeArea position="bottom" />
      </div>
    </div>
  )
}

export default MobileView
