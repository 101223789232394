/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react'

import { Layout } from 'antd'
import { SafeArea } from 'antd-mobile'

import Notification from 'utils/notification'

import BaseHeader from './Header'
import Sidebar from './Sidebar'

const MobileView = ({ children }) => {
  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      const keyboardActiveHeight = window.innerHeight + 60
      setHeight(prevheight =>
        prevheight >= keyboardActiveHeight
          ? keyboardActiveHeight
          : window.innerHeight
      )
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div
      style={{
        height: `${height}px`,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      <div style={{ background: 'var(--adm-color-primary' }}>
        <SafeArea position="top" />
      </div>
      <div style={{ flex: 0 }}>
        <BaseHeader />
      </div>
      <Layout
        style={{
          flex: 1,
          display: 'flex',
          overflow: 'auto',
        }}
      >
        <Notification />
        {children}
      </Layout>
      <div style={{ flex: 0 }}>
        <Sidebar />
      </div>
      <div>
        <SafeArea position="bottom" />
      </div>
    </div>
  )
}

export default MobileView
