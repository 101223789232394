import React from 'react'
import { useIntl } from 'react-intl'

import Icon from '@ant-design/icons'
import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import PropTypes from 'prop-types'

function MobileView({ handleSignOut }) {
  const intl = useIntl()

  return (
    <Button
      type="text"
      icon={
        <Icon
          onClick={handleSignOut}
          style={{
            fontSize: 18,
            cursor: 'pointer',
          }}
          component={() => <FontAwesomeIcon icon={faSignOutAlt} />}
          data-testid="signoutid"
        />
      }
    >
      {intl.formatMessage({
        id: 'global.labels.exit',
      })}
    </Button>
  )
}

MobileView.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
}

export default MobileView
