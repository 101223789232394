import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Icon from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TabBar } from 'antd-mobile'
import PropTypes from 'prop-types'

import history from 'services/history'

export default function BottomTabBar({ onChange, newMainRoutes }) {
  const [activeKey, setActiveKey] = useState('home')
  const intl = useIntl()

  const mobileMainRoutes = (function flatten(list, parentIcon) {
    return list.reduce((acc, obj) => {
      const { children, icon = parentIcon, ...rest } = obj
      acc.push({ ...rest, icon })
      if (children) {
        // eslint-disable-next-line no-param-reassign
        acc = acc.concat(flatten(children, icon))
      }
      return acc
    }, [])
  })(newMainRoutes)

  function getMenuItem(item) {
    const currentRoute = mobileMainRoutes.find(menuItem => menuItem.id === item)

    return currentRoute
  }

  return newMainRoutes.length ? (
    <div>
      <TabBar activeKey={activeKey}>
        <TabBar.Item
          key={getMenuItem('home')?.id}
          icon={
            getMenuItem('home')?.icon && (
              <Icon
                component={() => (
                  <FontAwesomeIcon icon={['fal', getMenuItem('home')?.icon]} />
                )}
              />
            )
          }
          title={intl.formatMessage({ id: getMenuItem('home')?.label })}
          onClick={() => {
            const value = getMenuItem('home')?.id

            setActiveKey(value)
            onChange({
              selectedKeys: [value],
              item: {
                props: {
                  pathname: mobileMainRoutes.find(e => e.id === value).to
                    .pathname,
                },
              },
            })

            history.push(mobileMainRoutes.find(e => e.id === value).to.pathname)
          }}
        />

        <TabBar.Item
          key={getMenuItem('dashboard')?.id}
          icon={
            getMenuItem('dashboard')?.icon && (
              <Icon
                component={() => (
                  <FontAwesomeIcon
                    icon={['fal', getMenuItem('dashboard')?.icon]}
                  />
                )}
              />
            )
          }
          title={intl.formatMessage({ id: getMenuItem('dashboard')?.label })}
          onClick={() => {
            const value = getMenuItem('dashboard')?.id

            setActiveKey(value)
            onChange({
              selectedKeys: [value],
              item: {
                props: {
                  pathname: mobileMainRoutes.find(e => e.id === value).to
                    .pathname,
                },
              },
            })

            history.push(mobileMainRoutes.find(e => e.id === value).to.pathname)
          }}
        />

        <TabBar.Item
          key={getMenuItem('analysis-process')?.id}
          icon={
            getMenuItem('analysis-process')?.icon && (
              <Icon
                component={() => (
                  <FontAwesomeIcon
                    icon={['fal', getMenuItem('analysis-process')?.icon]}
                  />
                )}
              />
            )
          }
          title={intl.formatMessage({
            id: getMenuItem('analysis-process')?.label,
          })}
          onClick={() => {
            const value = getMenuItem('analysis-process')?.id

            setActiveKey(value)
            onChange({
              selectedKeys: [value],
              item: {
                props: {
                  pathname: mobileMainRoutes.find(e => e.id === value).to
                    .pathname,
                },
              },
            })

            history.push(mobileMainRoutes.find(e => e.id === value).to.pathname)
          }}
        />
      </TabBar>
    </div>
  ) : (
    <div />
  )
}

BottomTabBar.propTypes = {
  newMainRoutes: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
