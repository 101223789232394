import React from 'react'

import { Selector } from 'antd-mobile'
import PropTypes from 'prop-types'

const MobileView = ({ value, onChange }) => (
  <Selector
    options={[
      { label: 'English', value: 'en-US' },
      { label: 'Português', value: 'pt-BR' },
      { label: 'Español', value: 'es-ES' },
    ]}
    columns={3}
    value={[value]}
    onChange={v => onChange(v[0])}
  />
)

MobileView.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MobileView
