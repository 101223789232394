import React from 'react'

import { Avatar } from 'antd'
import { Popup, SafeArea } from 'antd-mobile'
import PropTypes from 'prop-types'

import Profile from './Profile'

function MobileView({
  visible,
  setVisible,
  userProfile,
  handleSubmit,
  items,
  size,
}) {
  return (
    <>
      <Avatar
        size={size}
        src={userProfile?.usu_foto || '/styles/img/avatars/avatar.png'}
        onClick={() => setVisible(true)}
        style={{
          cursor: 'pointer',
          marginLeft: 16,
          marginRight: 16,
        }}
      />
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false)
        }}
        onClose={() => {
          setVisible(false)
        }}
        position="top"
      >
        <SafeArea position="top" />
        <Profile
          handleSubmit={handleSubmit}
          values={userProfile}
          usersList={items}
          page
        />
      </Popup>
    </>
  )
}

MobileView.defaultProps = {
  size: 'default',
}

MobileView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id_usuario: PropTypes.number,
      usu_nome: PropTypes.string,
    })
  ).isRequired,
  setVisible: PropTypes.func.isRequired,
  size: PropTypes.oneOf(PropTypes.number, PropTypes.string),
  userProfile: PropTypes.shape({
    id_usuario: PropTypes.number,
    usu_email: PropTypes.string,
    usu_foto: PropTypes.string,
    usu_nome: PropTypes.string,
  }).isRequired,
  visible: PropTypes.bool.isRequired,
}

export default MobileView
